import React from "react";
import { Helmet } from "react-helmet";
import {LinkWithUTM as Link} from '../../components/LinkWithUTM'
import { StaticImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Trustpilot from '../../components/Trustpilot/Trustpilot'
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CqcApproved from '../../components/CqcApproved'

import "../../../scss/main.scss";

import {
	ConditionSplash,
	ConditionDescription,
	ConditionDescriptionRightPanel,
	ConditionDescriptionRightPanelParagraph,
	ConditionInfographicSection,
	ConditionInfographicSectionIntro,
	ConditionInfographicMainContent,
	ConditionInfographicDescription,
	ConditionInfographicDescriptionParagraph,
	ConditionsInforgraphicTypes,
	ConditionsInfographicTypesHeading,
	ConditionsInfographicTypesDetail,
	ConditionsInfographicTypesDetailSection,
	ConditionsInfographicSymptons,
	ConditionsInfographicSymptonsHeader,
	ConditionsInfographicSymptonDiagram,
	ConditionsInfographicSymptonDiagramMainCopy,
	ConditionsInfographicSymptonDiagramTopics,
	ConditionsInfographicSymptonDiagramTopic,
	ConditionsInfographicSymptonDiagramTopicHeading,
	ConditionsInfographicSymptonDiagramTopicCopy,
	ConditionStats,
	ConditionStatsMainContent,
	ConditionStat,
	ConditionStatCopy,
	ConditionStatCopyInline,
	ConditionStatCopyEmph,
	ConditionStatCopyEmphInline,
	ConditionLivein,
	ConditionLiveinHeader,
	ConditionLiveinHeaderCopy,
	ConditionLiveinHomeCard,
	ConditionLiveinHomeCardMainCopy,
	ConditionLiveinHomeCardMainTip,
	ConditionLiveinHomeCardMainTipTitle,
	ConditionLiveinHomeCardMainTipCopy,
	ConditionTrainingCard,
	ConditionTrainingCardMainCopy,
	ConditionTrainingCardTip,
	ConditionTrainingCardTipItem,
} from '../../components/Conditions'

export default function Dementia() {
	const dementiaBackgroundFluid = useStaticQuery(graphql`
		query {
			background: file(relativePath: {eq: "dementia-image-02.jpg"}){
				childImageSharp {
					fluid(quality: 90) {
					  ...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`).background.childImageSharp.fluid
	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Dementia care at home</title>
				<meta property="og:title" content="Edyn Care | Dementia care at home"/>
				<meta property="og:description" content="High quality care in the comfort of your own home."/>
				<meta property="og:image" content="https://www.edyn.care/assets/marketing-site/images/condition_dementia_splash.png"/>
				<meta property="og:url" content="https://www.edyn.care/condition/cancer" />

				<meta name="twitter:card" content="summary_large_image" />
				<meta property="twitter:domain" content="edyn.care" />
				<meta property="twitter:url" content="https://www.edyn.care/condition/cancer" />
				<meta name="twitter:title" content="Edyn Care | Dementia" />
				<meta name="twitter:description" content="High quality care in the comfort of your own home." />
				<meta name="twitter:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png" />
			</Helmet>

			<div className="container">
				<Header />
				<main>
					<ConditionSplash>
						<StaticImage
							src="../../../static/assets/marketing-site/images/circle-dementia.svg"
							placeholder="blurred"
						/>
						<h3>Dementia care at home</h3>
					</ConditionSplash>
					<ConditionDescription>
						<StaticImage
							src="../../../static/assets/marketing-site/images/condition_dementia_splash.png"
							placeholder="blurred"
						/>
						<ConditionDescriptionRightPanel>
							<h3>
								A dementia diagnosis does not mean the end of an active, happy
								and fulfilled life.
							</h3>
							<ConditionDescriptionRightPanelParagraph>
								It’s normal to be worried when someone you love gets diagnosed
								with dementia. The fear of the unknown can be unsettling.
								Despite this, it’s vital to remain positive. Thanks to ongoing
								research, live-in care techniques and assistive technology, it
								is possible to live well with dementia, and remain at home.
							</ConditionDescriptionRightPanelParagraph>
							<ConditionDescriptionRightPanelParagraph>
								At Edyn we know first hand the power of having familiar
								surroundings. We hope, therefore, to lighten the burden and
								help you through the experience by sharing some useful
								information that will enable you to ‘invite’ dementia into the
								home, rather than it kicking you out.
							</ConditionDescriptionRightPanelParagraph>
						</ConditionDescriptionRightPanel>
					</ConditionDescription>
					<ConditionInfographicSection>
						<ConditionInfographicSectionIntro>
							<h1>What is dementia?</h1>
							<p className="secondary pro">
								Dementia is a syndrome that occurs when the brain is damaged by diseases.
							</p>
						</ConditionInfographicSectionIntro>
						<ConditionInfographicMainContent>
							<ConditionInfographicDescription>
								<h3>
									Dementia can affect anyone, whatever their gender, ethnic
									group, class, education and there is no cure, although
									research is going on.
								</h3>
								<ConditionInfographicDescriptionParagraph>
									There are an estimated 100 types of Dementia. When your
									loved one is diagnosed with dementia, the diagnosing
									clinician should be able to tell your loved one what type
									they have. This is useful to know since symptoms and
									patterns of progression vary from one to another, and it may
									help you and your loved one feel more prepared for what the
									future holds.
								</ConditionInfographicDescriptionParagraph>
							</ConditionInfographicDescription>
							<ConditionsInforgraphicTypes>
								<ConditionsInfographicTypesHeading>
									Some prominent forms of dementia
								</ConditionsInfographicTypesHeading>
								<ConditionsInfographicTypesDetail>
									<ConditionsInfographicTypesDetailSection title={`Alzheimer’s disease`}>
										<ul>
											<li>
												Is the main form of dementia, accounting for
												around 60% - 80% of all dementia diagnosis in the
												UK.
											</li>
											<li>
												Usually affects a part of the brain called the
												hippocampus which relates to memory and emotion.
											</li>
											<li>
												The first sign of Alzheimer's disease is usually
												problems with memory, such as forgetting a
												conversation or the names of places or people.
											</li>
											<li>
												As the symptoms of Alzheimer’s disease often
												progress slowly it is commonly diagnosed fairly
												late due to symptoms of old age overlapping.
											</li>
										</ul>
									</ConditionsInfographicTypesDetailSection>
									<ConditionsInfographicTypesDetailSection title={`Vascular dementia`}>
										<ul>
											<li>
												Second most common type of dementia, affecting around 150,000 people in the UK.
											</li>
											<li>
												Most researched forms of dementia are usually caused by strokes or mini-strokes.
											</li>
											<li>
												Around 20% of people who have a stroke develop vascular dementia.
											</li>
											<li>
												Symptoms may be similar to those of Alzheimer’s though can also include visual problems, bladder control and paralysis.
											</li>
										</ul>
									</ConditionsInfographicTypesDetailSection>
									<ConditionsInfographicTypesDetailSection title={`Dementia with lewy bodies`}>
										<ul>
											<li>
												Is often mistaken for Alzheimer’s and shares symptoms with this and Parkinson’s.
											</li>
											<li>
												It is less understood and more complex, accounting for around 10%-15% of those with dementia.
											</li>
											<li>
												Lewy bodies are abnormal clumps of protein building up in the brain.
											</li>
											<li>
												Sleep disturbances, problems with movement, swallowing, tremors and hallucinations are among the main symptoms.
											</li>
										</ul>
									</ConditionsInfographicTypesDetailSection>
									<ConditionsInfographicTypesDetailSection title={`Frontotemporal dementia`}>
										<ul>
											<li>
												Rare group of dementia conditions which are commonly diagnosed at a younger age.
											</li>
											<li>
												It’s name derives from the effects it has on the brain’s frontal lobe which influences our behaviour and moral judgements.
											</li>
											<li>
												It has three main types: picks, progressive non-fluent aphasia and semantic dementia.
											</li>
											<li>
												Behaviour and speech issues are the usual symptoms.
											</li>
										</ul>
									</ConditionsInfographicTypesDetailSection>

								</ConditionsInfographicTypesDetail>
							</ConditionsInforgraphicTypes>
							<ConditionsInfographicSymptons>
								<ConditionsInfographicSymptonsHeader>
									There are many different types of dementia, the most
									common being Alzheimer’s disease. It is progressive,
									meaning that symptoms will eventually get worse. It starts
									as ‘mild cognitive impairment’ (MCI): forgetfulness or
									‘senior moments”. But as it progresses the symptoms vary
									depending on the type.
								</ConditionsInfographicSymptonsHeader>
								<ConditionsInfographicSymptonDiagram>
									<ConditionsInfographicSymptonDiagramMainCopy>
										Each type of dementia may affect different parts of
										the brain and thus result in different symptoms,
										though the early symptoms of dementia may be mild or
										increase fairly rapidly depending on a person of type.
										Many tend to think of dementia as mainly memory loss,
										but there is quite often a range of early symptoms
										which can be key indicators.
									</ConditionsInfographicSymptonDiagramMainCopy>
									<ConditionsInfographicSymptonDiagramTopics>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>Memory loss:</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												Such as struggling to recall a familiar face or name
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>Confusion:</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												Such as misplacing personal items, like glasses and medication
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>
												Linguistic problems:
											</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												Such as pauses in sentences and difficulty finding words
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>
												Increase movement:
											</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												An uptick in movement around the house
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>
												Changes in mood and behaviour:
											</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												Such as a loss of interest in hobbies
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
									</ConditionsInfographicSymptonDiagramTopics>
								</ConditionsInfographicSymptonDiagram>
							</ConditionsInfographicSymptons>
						</ConditionInfographicMainContent>
					</ConditionInfographicSection>
					<ConditionStats>
						<BackgroundImage className="f-c-image" fluid={dementiaBackgroundFluid}>
							<h1>Dementia facts and stats</h1>
						</BackgroundImage>
						<ConditionStatsMainContent>
							<ConditionStat>
								<ConditionStatCopyEmph>850,000</ConditionStatCopyEmph>
								<ConditionStatCopy>
									people in the UK living with dementia
								</ConditionStatCopy>
								{/*   <hr>  */}
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopyInline>Dementia attracts 	</ConditionStatCopyInline>
								<ConditionStatCopyEmphInline>7.4%</ConditionStatCopyEmphInline>
								<ConditionStatCopy>
									of the research funding that goes into cancer
								</ConditionStatCopy>
								{/*   <hr>  */}
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopy>
									A person is diagnosed with dementia every
								</ConditionStatCopy>
								<ConditionStatCopyEmph>three minutes</ConditionStatCopyEmph>
								{/*   <hr>  */}
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopyEmph>Over 100</ConditionStatCopyEmph>
								<ConditionStatCopy>different types of dementia</ConditionStatCopy>
								{/*   <hr>  */}
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopyEmph>70%</ConditionStatCopyEmph>
								<ConditionStatCopy>
									of people in care homes have dementia
								</ConditionStatCopy>
								{/*   <hr>  */}
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopyEmph>Disproportionately</ConditionStatCopyEmph>
								<ConditionStatCopy>affects women</ConditionStatCopy>
								{/*   <hr>  */}
							</ConditionStat>
						</ConditionStatsMainContent>
					</ConditionStats>
					<ConditionLivein>
						<ConditionLiveinHeader>
							<h1>Dementia live-in care </h1>
							<ConditionLiveinHeaderCopy>
								We understand that with patience, trust and genuine care, we
								can help those diagnosed with dementia maintain a high quality
								of life and live comfortably in their own home.
							</ConditionLiveinHeaderCopy>
						</ConditionLiveinHeader>
						<ConditionLiveinHomeCard title={`Ensuring your home is dementia friendly`}>
							<ConditionLiveinHomeCardMainCopy>
								When considering live-in care it’s important to ensure that
								the home of the care recipient is safe and secure. Does it
								compensate for any difficulties they are experiencing? Does
								it keep them safe? Does it support their mobility? <br />
								<br />
								At Edyn as part of our approach we complete a home risk
								assessment for all of our clients. Our care managers ensure
								that your home is the best possible setting for care. Small
								changes can make big differences.
							</ConditionLiveinHomeCardMainCopy>
							<ConditionLiveinHomeCardMainTip>
								<ConditionLiveinHomeCardMainTipTitle>Top dementia care tip:</ConditionLiveinHomeCardMainTipTitle>
								<br />
								<ConditionLiveinHomeCardMainTipCopy>
									Covering reflective surfaces, laying plain and consistent
									flooring and removing bold patterns are all quick wins.
								</ConditionLiveinHomeCardMainTipCopy>
							</ConditionLiveinHomeCardMainTip>
						</ConditionLiveinHomeCard>
						<ConditionTrainingCard title={`Professional carers trained specifically for dementia:`}>
							<ConditionTrainingCardMainCopy>
								Our professional care teams receive on-going training,
								mentoring and support based on the latest thinking and
								research that ensure they have the skills to deliver
								positive dementia care. This includes:
							</ConditionTrainingCardMainCopy>
							<ConditionTrainingCardTip>
								<ConditionTrainingCardTipItem>Creative activities</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Monitoring behaviour and cognitive symptoms</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Active recall of preserving memories</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Sensory and dental care</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Maintaining exercise</ConditionTrainingCardTipItem>
							</ConditionTrainingCardTip>
						</ConditionTrainingCard>
					</ConditionLivein>
					{/* <section>
						<div className="customer-say">
							<div className="customer-words">
								<p className="secondary">What our customers say:</p>
								<div className="customer-words-group">
									<h3 className="text-with-quotes">
										<span className="customer-words-group__quotes">“</span>
										What makes Edyn stand out from other agencies we have used
										for live-in care is the fact that they care - the human
										touch is there.
										<span className="customer-words-group__quotes">”</span>
									</h3>
								</div>
							</div>
							<div className="customer-images">
								<div className="customer-images-bubbles">
									<StaticImage placeholder="blurred" src="../../../static/assets/marketing-site/images/dementia-quote-customers1.png" />
									<StaticImage placeholder="blurred" src="../../../static/assets/marketing-site/images/dementia-quote-customers2.png" />
								</div>
								<div className="customer-names">
									<p className="secondary">
										Sarah M.
										<br />
										<span>Mum</span>
									</p>
									<p className="secondary">
										Julie M.
										<br />
										<span>Daughter</span>
									</p>
								</div>
							</div>
						</div>
					</section> */}

					<section className="live-in dementia">
						<h1 className="live-in-heading">
							Why choose live-in care with Edyn
						</h1>
						<div className="reasons">
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../../static/assets/marketing-site/images/proffesional-carer.png"
									alt="proffesional carers"
								/>
								<h3>First class professional carers</h3>
								<p className="primary">
									Each one of our carers is trained in house by our care support
									team.
								</p>
							</div>
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../../static/assets/marketing-site/images/support-teams.png"
									alt="Support Teams"
								/>
								<h3>Support teams to manage your care.</h3>
								<p className="primary">
									Our dedicated care specialists will handle the progression of
									your care.
								</p>
							</div>
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../../static/assets/marketing-site/images/technology.png"
									alt="Technology"
								/>
								<h3>Technology to keep everyone updated</h3>
								<p className="primary">
									Stay informed and up to date with our smart online portal.
								</p>
							</div>
						</div>
						<Link to="/approach/" className="button-link">
							<button className="btn-primary">
								Learn more about our process
							</button>
						</Link>
					</section>
					{/* <Trustpilot /> */}
					<CqcApproved />
				</main>
				<Footer />
			</div>
		</>
	);
}
